import {
    ADD_TO_INCLUDED_TAGS,
    REMOVE_FROM_INCLUDED_TAGS,
    ADD_TO_EXCLUDED_TAGS,
    REMOVE_FROM_EXCLUDED_TAGS,
    ADD_TERM,
    REMOVE_TERM,
    SET_DURATION,
    REMOVE_DURATION,
    SET_BPM,
    REMOVE_BPM,
    SET_TEMPOS,
    REMOVE_TEMPO,
    SAVE_SEARCH_TRACKS_QUERY_ID,
    SHOW_TAGS,
    ADD_TO_LABELS,
    REMOVE_FROM_LABELS,
} from '@App/store/actions/search';
import { SIMILARITY_SET_URL, SIMILARITY_SET_FILE, SIMILARITY_SET_TRACK } from '@App/store/actions/similarity';
import { addUnique, removeUnique } from '@App/store/reducers/utils/reducer-helpers';
import { isSearching as isSimilaritySearching } from '@App/store/reducers/similarity';

const initialState = {
    terms: [],
    includedTags: [],
    excludedTags: [],
    labels: [],
    searchTracksQueryID: null,
    duration: { min: null, max: null },
    bpm: { min: null, max: null },
    tempos: [],
    showTags: false,
};

// UTILS FONCTIONS

/**
 * Is tag included?
 *
 * @param  {Object}  state
 * @param  {Number}  id
 *
 * @return {Boolean}
 */
export function isTagIncluded(state, id) {
    return state.search.includedTags.includes(id);
}

/**
 * Is tag included?
 *
 * @param  {Object}  state
 * @param  {Number}  id
 *
 * @return {Boolean}
 */
export function isTagExcluded(state, id) {
    return state.search.excludedTags.includes(id);
}

/**
 * Is label active?
 *
 * @param  {Object}  state
 * @param  {Number}  id
 *
 * @return {Boolean}
 */
export function isLabelActive(state, id) {
    return state.search.labels.includes(id);
}

/**
 * Is currently searching?
 *
 * @param  {Object}  state
 *
 * @return {Boolean}
 */
export function isSearching(state) {
    const { terms, includedTags, excludedTags, duration, bpm, labels, tempos } = state.search;

    if (isSimilaritySearching(state)) {
        return false;
    }

    return terms.length > 0
        || includedTags.length > 0
        || excludedTags.length > 0
        || labels.length > 0
        || duration.min !== null
        || duration.max !== null
        || bpm.min !== null
        || bpm.max !== null
        || tempos.length > 0;
}

/**
 * Search
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function search(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SAVE_SEARCH_TRACKS_QUERY_ID:
            return { ...state, searchTracksQueryID: payload.queryID };

        case ADD_TERM:
            return { ...state, terms: addUnique(state.terms, payload.term) };

        case REMOVE_TERM:
            return { ...state, terms: removeUnique(state.terms, payload.term) };

        case SET_DURATION:
            return { ...state, duration: { min: payload.min, max: payload.max } };

        case REMOVE_DURATION:
            return { ...state, duration: { min: null, max: null } };

        case SET_BPM:
            return { ...state, bpm: { min: payload.min, max: payload.max } };

        case REMOVE_BPM:
            return { ...state, bpm: { min: null, max: null } };

        case SET_TEMPOS:
            return { ...state, tempos: payload.tempos };

        case REMOVE_TEMPO:
            return { ...state, tempos: state.tempos.filter(tempo => tempo !== payload.tempo) };

        case ADD_TO_INCLUDED_TAGS:
            return {
                ...state,
                includedTags: addUnique(state.includedTags, payload.id),
                excludedTags: removeUnique(state.excludedTags, payload.id),
            };

        case REMOVE_FROM_INCLUDED_TAGS:
            return {
                ...state,
                includedTags: removeUnique(state.includedTags, payload.id),
            };

        case ADD_TO_EXCLUDED_TAGS:
            return {
                ...state,
                includedTags: removeUnique(state.includedTags, payload.id),
                excludedTags: addUnique(state.excludedTags, payload.id),
            };

        case REMOVE_FROM_EXCLUDED_TAGS:
            return {
                ...state,
                excludedTags: removeUnique(state.excludedTags, payload.id),
            };

        case ADD_TO_LABELS:
            return { ...state, labels: addUnique(state.labels, payload.id) };

        case REMOVE_FROM_LABELS:
            return { ...state, labels: removeUnique(state.labels, payload.id) };

        case SHOW_TAGS:
            return { ...state, showTags: payload.showTags };

        case SIMILARITY_SET_URL:
        case SIMILARITY_SET_FILE:
        case SIMILARITY_SET_TRACK:
            // Clear search when a similarity search is performed.
            return initialState;

        default:
            return state;
    }
}
